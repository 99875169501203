import { Questions } from "./Questions";
import { AboutUs } from "./AboutUs";
import { Partnerts } from "./Partners";
import { CollectionsHome } from "./CollectionHome";


export default function Marketplace() {

    return (
        <div id="home">
            <section className="spacetop15 spacebottom10">
                <div className="container">
                    <div className="row jc-between ai-center col-reverse-s">
                        <div className="col5 col6-md col12-s ta-center-s">
                            <h1 className="size6 bold spacebottom1 titlegreen">
                                Conviértete en Socio Fundador de Promolíder y obtén tu NFT
                            </h1>
                            <p className="size2 spacebottom3 halfwhite">"Estamos contentos de ser liderados por ti y por tu gran visión de negocios"
                                <br /><i>~Giovany Perni. CEO Promolider</i>
                            </p>

                            <div className="row col8 col9-md jc-between spacebottom4 jc-evenly-s col12-s">
                                <a href="#collections" className="btn bg-green size2 white ">Explorar ahora</a>
                                <a href="#about" className="btn bg-white10 size2 white ">Nuestros socios</a>
                            </div>

                        </div>
                        <div className="col7 col13-s spacebottom3-s">
                            <img src="img/PromoliderImage.png" alt="" className="img-responsive float" />
                        </div>
                    </div>
                </div>
            </section>

            <AboutUs/>

            <CollectionsHome/>

            {/* <Featured/> */}

            <Partnerts/>

            <Questions/>
        </div >
    );
}
