import {   useRef, useState } from "react";
import { uploadFileToIPFS, uploadJSONToIPFS } from "../../pinata";
import Marketplace from '../../Promolider.json';
import Marketplace2 from '../../Promolider2.json';
import Marketplace3 from '../../Promolider3.json';
import { ethers } from "ethers";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebaseCredentials";
import { useAddress } from "../../Context/Account";

const Logout=()=><svg  xmlns="http://www.w3.org/2000/svg"  width="36"  height="36"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="1"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-logout-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2" /><path d="M15 12h-12l3 -3" /><path d="M6 15l-3 -3" /></svg>

export default function SellNFT () {
    const [formParams, updateFormParams] = useState({ name: '', description: '', price: "",collection:"",amount:0,terminos:"",list:null});
    const [fileURL, setFileURL] = useState(null);
    const [isloading,setLoading]=useState(false)
    const [over,setOver]=useState(false)
    const [messageFinal,setMessageFinal]=useState("")
    const {connectWebsite,address}=useAddress()
    const tip=useRef(null)
    const file=useRef(null)

    async function OnChangeFile(e) {
        const file = e.target.files[0];
        setFileURL(file);
    }

    async function uploadMetadataToIPFS(image) {
        const {name, description,terminos,price} = formParams;

        const nftJSON = {
            name, 
            description, 
            image,
            owner:address,
            terminos,
            price,
        }

        try {
            //upload the metadata JSON to IPFS
            const response = await uploadJSONToIPFS(nftJSON);
            if(response.success === true){
                return response.pinataURL;
            }
        }
        catch(e) {
            console.log("error uploading JSON metadata:", e)
        }
    }

    const toolTip=(msg,state)=>{
        setMessageFinal(msg)
        tip.current.style.transform="translateX(0%)"
        tip.current.style.borderLeft =`7px solid ${state?"#249729":"red"}`
        tip.current.style.color =`${state?"#249729":"red"}`
        setTimeout(() => tip.current.style.transform="translateX(100%)", 2000);
    }

    async function listNFT(e) {
        e.preventDefault();
        setLoading(true)
        await connectWebsite()
        try {

            const {name, description, price,collection,terminos} = formParams;
            
            if( !name || !description || !price || !fileURL || !collection || !terminos) return ;
            
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner();
            
            const nameCollection=formParams.collection    

            const contractDecided={}

            if (nameCollection==0) {
                contractDecided.address=Marketplace.address
                contractDecided.abi=Marketplace.abi
            }else if(nameCollection==1){
                contractDecided.address=Marketplace2.address
                contractDecided.abi=Marketplace2.abi
            }else if(nameCollection==2){
                contractDecided.address=Marketplace3.address
                contractDecided.abi=Marketplace3.abi
            }

            const contract = new ethers.Contract(contractDecided.address, contractDecided.abi, signer)
            
            const response = await uploadFileToIPFS(fileURL);
            
            if(response.success !== true) throw new Error("Error upload Image") 
                
                const imageUrl=response.pinataURL
                
                const metadataURL = await uploadMetadataToIPFS(imageUrl);
                
                const priceParsed = ethers.utils.parseUnits(formParams.price, 'ether')
                
            let listingPrice = await contract.getListPrice()

            const amount=formParams.amount*100

            listingPrice = listingPrice.toString()
            const transaction = await contract.createToken(metadataURL, priceParsed,amount,formParams.list,{ value: listingPrice })
            await transaction.wait() 
            toolTip("Successfully listed your NFT!",true)
            setFileURL(null)
            updateFormParams({ name: '', description: '', price: '',collection:"",terminos:"",amount:0,list:null});
            setLoading(false)
        }
        catch(e) {
            toolTip("Error upload NFT!")
        }finally{
            setLoading(false)
        }
    }

    const handleForm=(e)=>{
        updateFormParams({...formParams, [e.target.name]: e.target.value})
    }

    const handleDrop=(e)=>{
        e.preventDefault()
        const [file]=e.dataTransfer.files
        setFileURL(file)
        setOver(false)
    }

    const handleDragOver=(e)=>{
        e.preventDefault()
        setOver(true)
    }


    const handleDragLeave=(e)=>{
        e.preventDefault()
        setOver(false)
    }

    const handleChangeSelect=(e)=>{
        updateFormParams(prev=>({...prev,collection:e.target.value}))
    }
    
    const handleChangeListed=(e)=>{
        const value=e.target.value
        const status=value==="true"?true:false
        updateFormParams(prev=>({...prev,list:status}))
    }


    return (
        <>
            {isloading && <div className="fixed bg-white/50 backdrop-blur-xl top-0 bottom-0 left-0 w-full z-50 text-[#249729] flex  items-center justify-center text-7xl">Loading....</div>}
            {<div className="fixed top-0 right-0 z-50 text-3xl translate-x-[100%] h-40 bg-white rounded-xl px-4 py-2 flex flex-wrap w-[300px] shadow-2xl justify-center items-center" ref={tip}>{messageFinal}</div>}
            <div className="relative h-20">
                <button className="text-3xl absolute right-12 top-40 flex gap-x-4 items-center py-2 px-12 rounded-3xl bg-[#36ca3d] hover:bg-opacity-50 cursor-pointer" 
                onClick={()=>signOut(auth)}>Logout <Logout/></button>
            </div>
            <div className="flex flex-col place-items-center mt-60 h-auto relative" id="nftForm">
            <form className="bg-white/20 shadow-md px-8 pt-4 pb-8 mb-4 rounded-2xl grid md:grid-cols-2 gap-5 h-auto max-w-[700px]">
                <h3 className="text-center font-bold text-[#13aa1ade] mb-8 text-4xl col-span-2">Upload your NFT to the marketplace</h3>
                <section className="col-span-2 md:col-span-1">
                    <div className="mb-6">
                        <label className="block text-[#13aa1ade] text-2xl font-bold mb-2 " htmlFor="name">NFT Name</label>
                        <input className="shadow text-2xl rounded-xl w-full py-4 px-3 text-gray-700 focus:outline-none focus:shadow-outline" id="name" type="text" 
                        name="name"
                        placeholder="Nft name" onChange={handleForm} value={formParams.name}></input>
                    </div>
                    <div className="mb-6">
                        <label className="block text-[#13aa1ade] text-2xl font-bold mb-2 " htmlFor="name">NFT Collection</label>
                        <select name="" className="text-2xl text-black w-full rounded-xl px-4 py-2" onChange={handleChangeSelect}>
                            <option value="0" className="text-black">seleciione el numero de coleccion</option>
                            <option value="0" className="text-black">Primera Coleccion</option>
                            <option value="1" className="text-black">Segunda Coleccion</option>
                            <option value="2" className="text-black">Tercera Coleccion</option>
                        </select>
                    </div>
                    <div className="mb-6">
                        <label className="block text-[#13aa1ade] text-2xl font-bold mb-2" htmlFor="description">NFT Description</label>
                        <textarea className="shadow text-2xl rounded-xl w-full py-4 px-3 text-gray-700 focus:outline-none focus:shadow-outline" cols="40" rows="5" id="description" type="text" placeholder="Nft Collection Description..." value={formParams.description}
                        name="description" 
                        onChange={handleForm}></textarea>
                    </div>
                    <div className="mb-6">
                        <label className="block text-[#13aa1ade] text-2xl font-bold mb-2" htmlFor="description">Optional terminos</label>
                        <textarea className="shadow text-2xl rounded-xl w-full py-4 px-3 text-gray-700 focus:outline-none focus:shadow-outline" cols="40" rows="5" id="terminos" type="text" placeholder="Terminos....." value={formParams.terminos}
                        name="terminos" 
                        onChange={handleForm}></textarea>
                    </div>
                    <div>
                        <select name="" className="text-2xl text-black w-full rounded-xl px-4 py-2 mb-2" onChange={handleChangeListed}>
                            <option value="" className="text-black">Quieres Listar el Nft en el Marketplace</option>
                            <option value={true} className="text-black">Yes</option>
                            <option value={false} className="text-black">No</option>
                        </select>
                    </div>
                    <div className="mb-6">
                        <label className="block text-[#13aa1ade] text-2xl font-bold mb-2" htmlFor="price">Porcentaje en otras plataformas</label>
                        <input className="shadow text-2xl rounded-xl w-full py-4 px-3 text-gray-700 focus:outline-none focus:shadow-outline" type="number"
                        name="amount" min={0} max={100}
                        placeholder="Min 0%" step="1" value={formParams.amount} onChange={handleForm}></input>
                    </div>
                </section>
                <section className="col-span-2 md:col-span-1">
                    <div className="mb-6">
                        <label className="block text-[#13aa1ade] text-2xl font-bold mb-2" htmlFor="price">Price (in Matic)</label>
                        <input className="shadow text-2xl rounded-xl w-full py-4 px-3 text-gray-700 focus:outline-none focus:shadow-outline" type="number"
                        name="price" min={0.0001}
                        placeholder="Min 0.0001 MATIC" step="0.001" value={formParams.price} onChange={handleForm}></input>
                    </div>

                    <div>
                        <label className="block text-[#13aa1ade] text-2xl font-bold mb-2" htmlFor="image">Upload Image</label>
                        <input type={"file"} onChange={OnChangeFile} accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/webp" ref={file} className="hidden"/>
                    </div>

                    <div className={`border-dashed h-40 rounded-xl flex items-center justify-center text-2xl ${over?"border-[#36ca3d] scale-125":""} transition-all ease-in-out duration-300`} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                        <p>Drag Files here or <span className="cursor-pointer text-[#13aa1ade] " onClick={()=>file.current.click()}>browse</span></p>
                    </div>


                    <div className="flex justify-center items-center mt-5">
                        {fileURL&&<img src={URL.createObjectURL(fileURL)} alt="image nft promolider" className="w-96 h-96 object-contain" />}
                    </div>
                    <div className="text-white">
                        <h2 className="text-2xl mb-5">Total en futuras reventas</h2>
                        <p className="text-xl">El reingreso de este nft sera de {formParams.amount}% de su venta para la plataforma de Promolider</p>
                    </div>
                    <button onClick={listNFT} className="mt-10 w-full text-3xl bg-[#13aa1ade] text-white p-2 shadow-lg rounded-xl hover:bg-[#266129de]">
                        List NFT
                    </button>
                </section>
            </form>
        </div>
        </>
    )
}