import { useEffect, useRef } from "react"

export function AboutUs() {

    const img=useRef(null)

    useEffect(()=>{

        const view=(entries)=>{
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.style.transform ="translateX(0%)";
                    entry.target.style.opacity ="1";
                }
            });
        }
        const imagen=img.current
        
        const Observer= new IntersectionObserver(view,{root:null,rootMargin:"-200px",threshold:"0.1"})
        
        Observer.observe(imagen)

        return ()=>Observer.unobserve(imagen)

    },[])

    return(
        <section id="about" className="spacer10 overflow-hidden">
                <div className="container">
                    <h1 className="bold size6 ta-center titlegreen">Sobre nosotros</h1>
                    <p className="spacebottom3 halfwhite size2 ta-center">
                        Conoce un poco más sobre<br />nosotros
                    </p>
                    <div className="row ai-center jc-between flexcol-s spacer3">
                        <div className="col6 col12-s ta-center-s">
                            <h3 className="size45 bold titlegreen">¿Qué son los NFT de Promolíder?</h3>
                            <p className="size25 spacetop1 spacebottom3 halfwhite">Promolíder lanza al mercado sus propios
                                NFT con la garantía del 10% de la utilidad
                                de la empresa, siendo estos de origen único
                                no repetible y coleccionable, que brinda la
                                oportunidad al poseedor de revalorizar su
                                inversión de forma exponencial, convirtiéndose
                                en uno de los 100 Socios Fundadores.
                            </p>
                        </div>
                        <div className="col6 col10-s spacebottom2-s">
                            <img src="/assets/img/nft png.gif" className="nft-logo  translate-x-96 opacity-0 transition-all ease-in-out duration-700" alt="image buho nft" ref={img} />
                        </div>
                    </div>
                </div>
            </section>
    )
}