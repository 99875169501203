import { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import MarketplaceJSON from "../../Promolider.json";
import MarketplaceJSON2 from "../../Promolider2.json";
import MarketplaceJSON3 from "../../Promolider3.json";
import axios from "axios";
import { useAddress } from "../../Context/Account";
import { ProfileCOmponent } from "./ProfileComponent";

export function Profile() {
        
    const [data, updateData] = useState([]);
    const {address,setAddress}=useAddress()
    const [totalPrice, updateTotalPrice] = useState(0);
    const [loading,setLoading]=useState(true)
    const [message,setMessage]=useState(null)
    const [isloadingTransfer,setTransferLoading]=useState(false)
    const tip=useRef(null)

    const toolTip=(msg,state)=>{
        setMessage(msg)
        tip.current.style.transform="translateX(0%)"
        tip.current.style.borderLeft =`7px solid ${state?"#249729":"red"}`
        tip.current.style.color =`${state?"#249729":"red"}`
        setTimeout(() => tip.current.style.transform="translateX(100%)", 2000);
    }
    

    useEffect(()=>{
        async function getNFTData() {
            try {
                
                let sumPrice = 0;
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const addr = await signer.getAddress();
                
                const contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer)
                const contract2 = new ethers.Contract(MarketplaceJSON2.address, MarketplaceJSON2.abi, signer)
                const contract3 = new ethers.Contract(MarketplaceJSON3.address, MarketplaceJSON3.abi, signer)
                
                const transaction = await contract.getMyNFTs()
                const transaction2 = await contract2.getMyNFTs()
                const transaction3 = await contract3.getMyNFTs()

                const items = await Promise.allSettled(transaction.map(async i => {
                    const tokenURI = await contract.tokenURI(i.tokenId);
                    
                    let meta = await axios.get(tokenURI);
                    meta = meta.data;
                    
                    let price = ethers.utils.formatUnits(i.price.toString(), 'ether');
                    let item = {
                        price,
                        tokenId: i.tokenId.toNumber(),
                        seller: i.seller,
                        owner: i.owner,
                        listed:i.currentlyListed,
                        image: meta.image,
                        name: meta.name,
                        collection:"Promolider1°",
                        description: meta.description, 
                    }
                    sumPrice += Number(price);
                    return item;
                }))
                
                const items2 = await Promise.allSettled(transaction2.map(async i => {
                    const tokenURI = await contract2.tokenURI(i.tokenId);
                    
                    let meta = await axios.get(tokenURI);
                    meta = meta.data;
                    
                    let price = ethers.utils.formatUnits(i.price.toString(), 'ether');
                    let item = {
                        price,
                        tokenId: i.tokenId.toNumber(),
                        seller: i.seller,
                        owner: i.owner,
                        listed:i.currentlyListed,
                        image: meta.image,
                        name: meta.name,
                        collection:"Promolider2°",
                        description: meta.description, 
                    }
                    sumPrice += Number(price);
                    return item;
                }))

                const items3 = await Promise.allSettled(transaction3.map(async i => {
                    const tokenURI = await contract3.tokenURI(i.tokenId);
                    
                    let meta = await axios.get(tokenURI);
                    meta = meta.data;
                    
                    let price = ethers.utils.formatUnits(i.price.toString(), 'ether');
                    let item = {
                        price,
                        tokenId: i.tokenId.toNumber(),
                        seller: i.seller,
                        owner: i.owner,
                        listed:i.currentlyListed,
                        image: meta.image,
                        name: meta.name,
                        collection:"Promolider3°",
                        description: meta.description, 
                    }
                    sumPrice += Number(price);
                    return item;
                }))

                const result=[...items,...items2,...items3]
                updateData(result);
                setAddress(addr);
                updateTotalPrice(sumPrice.toPrecision(3));
            } catch (error) {
                updateData([])
            }finally{
                setLoading(false)

            }
        }
        if (!address) return setLoading(false)
        getNFTData();
    },[address])

    return(
        <section className="creator">
             {isloadingTransfer && <div className="fixed bg-white/50 backdrop-blur-xl top-0 bottom-0 left-0 w-full z-50 text-[#249729] flex  items-center justify-center text-7xl">Loading....</div>}
             {<div className="fixed top-0 right-0 z-50 text-3xl translate-x-[100%] h-40 bg-white rounded-xl px-4 py-2 flex flex-wrap w-[300px] shadow-2xl justify-center items-center" ref={tip}>{message}</div>}
                <div className="container">
                    <h1 className="bold size6 ta-center titlegreen">MIS NFTS</h1>
                    <div className="profileClass">
                        <div className="flex text-center flex-col mt-11 md:text-2xl text-white">
                            <div className="mb-5">
                                <h2 className="font-bold size3 titlegreen">ID de Billetera</h2>
                                {address}
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col text-center justify-center mt-10 md:text-2xl text-white">
                            <div>
                                <h2 className="font-bold size3 titlegreen">N° de NFTs</h2>
                                {data.length}
                            </div>
                            <div className="md:ml-20 m-0">
                                <h2 className="font-bold size3 titlegreen">Valor total</h2>
                                {totalPrice} Matic
                            </div>
                        </div>
                        <div className="flex flex-col text-center mt-11 text-white">
                            <ProfileCOmponent data={data} loading={loading} toolTip={toolTip} setTransferLoading={setTransferLoading}/>
                        </div>
                    </div>
                </div>
            </section >
    )
}