import { createContext, useContext, useState } from "react";

const AccountContext=createContext()

export function AccountProvider({children}) {
    
    const [address,setAddress]=useState("")
    const [connected, toggleConnect] = useState(false);


    async function connectWebsite() {

        if (!window.ethereum) return window.open('https://metamask.io/download/', '_blank');
        const chainId = await window.ethereum.request({ method: 'eth_chainId'});
        try {
          if (chainId !== process.env.REACT_APP_CHAIN_ID) {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
            });
          }
        } catch (error) {
          if (error.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: process.env.REACT_APP_CHAIN_ID ,
                    chainName: process.env.REACT_APP_CHAIN_NAME,
                    rpcUrls: [process.env.REACT_APP_RPC_URL],
                    nativeCurrency: {
                      name: process.env.REACT_APP_CHAIN_NAME_NATIVE,
                      symbol: process.env.REACT_APP_CHAIN_SYMBOL_NATIVE ,
                      decimals: parseInt(process.env.REACT_APP_DECIMALS),
                    },
                    blockExplorerUrls: [process.env.REACT_APP_BLOCKEXPLORERURL,'https://polygonscan.com/'],
                  },
                ],
              });
            } catch (addError) {
              console.error('Error al añadir la red Polygon:', addError);
              return;
            }
          } else {
            console.error('Error al cambiar de red:', error);
            return;
          }
        }
          
    
        const address=await window.ethereum.request({ method: 'eth_requestAccounts' })
        if (address.length>0) {
          setAddress(address[0])
          toggleConnect(true)
          sessionStorage.setItem("connect",true)
          sessionStorage.setItem("address",address[0])
        }
      }

    return(
        <AccountContext.Provider value={{address,setAddress,connected,toggleConnect,connectWebsite}}>
            {children}
        </AccountContext.Provider>
    )
}

export function   useAddress() {
    
    const {address,setAddress,connected,toggleConnect,connectWebsite}=useContext(AccountContext)

    return {address,setAddress,connected,toggleConnect,connectWebsite}
}