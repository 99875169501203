import {  useState } from "react";
import { CollectCard } from "./Cards";

const sampleData=[{
    value:
    {
        price:"price",
        image: "/img/NFT001.png",
        name: "Buho Estudiante",
        description: "Buho description",
    }},
    {value:
    {
        price:"price",
        image: "/img/NFT002.png",
        name: "Buho Graduado",
        description: "Buho description",
    }},
    {value:
        {
            price:"price",
            image: "/img/NFT003.png",
            name: "Buho Nadador",
            description: "Buho description",
    }},
    {value:
        {
            price:"price",
            image: "/img/NFT004.png",
            name: "Buho Nadador",
            description: "Buho description",
    }},
    {value:
        {
            price:"price",
            image: "/img/NFT005.png",
            name: "Buho Nadador",
            description: "Buho description",
    }},
    {value:
        {
            price:"price",
            image: "/img/NFT006.png",
            name: "Buho Nadador",
            description: "Buho description",
    }},
    {value:
        {
            price:"price",
            image: "/img/NFT007.png",
            name: "Buho Nadador",
            description: "Buho description",
    }},
    {value:
        {
            price:"price",
            image: "/img/NFT008.png",
            name: "Buho Nadador",
            description: "Buho description",
    }},
    {value:
        {
            price:"price",
            image: "/img/NFT009.png",
            name: "Buho Nadador",
            description: "Buho description",
    }}
]

export function CollectionsHome() {
    const [data, updateData] = useState(()=>sampleData);
  
    return (
        <section className="collections" id="collections">
                <div className="container grid place-content-center">
                    <h1 className="bold size6 ta-center titlegreen">Colecciones</h1>
                    <p className="spacebottom3 halfwhite size2 ta-center">
                        Visualiza nuestra gran colección de NFT<br />que tenemos para ti
                    </p>
                    <CollectCard data={data} loading={false}/>
                </div>
            </section>
    )
}