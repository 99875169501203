import { Profile } from "./Profile";

export default function ProfilePage() {

    return (

        <div className="profileClass pt-40" > 
            <Profile/>
        </div>

    )
};