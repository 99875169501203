import { Link } from "react-router-dom";
import "../../NFTTile.css";
    
function ProfileCard({data}) {

    const route=data?.tokenId
  
  return (
    <div className="card">
      <Link
      to={`/profile/nft/${data.collection}/${route}`}>

      <img src={data.image} className="card-img " alt="" />
      <div className="info">
        <div className="flex justify-between items-center mb-2">
          <p className="text-gray-400">@Promolider</p>
          <p className="text-gray-400">Matic</p>
        </div>
        <div className="flex justify-between items-center mb-4">
          <h5 className="font-bold">{data.name}</h5>
          <h5 className="font-bold">{data.price} MATIC</h5>
        </div>
      </div>
    </Link>
    </div>
  );
}

export default ProfileCard;
