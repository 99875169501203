import {  useRef, useState } from "react";
import { ethers } from "ethers";
import MarketplaceJSON from "../../Promolider.json";


export function ProfilePageSell({data}) {

    const [priceSell,setPrice]=useState(0)
    const [wallet,setWallet]=useState("")
    const [loading,setLoading]=useState(false)
    const [message,setMessage]=useState(null)

    const tip=useRef(null)

    const toolTip=(msg,state)=>{
        setMessage(msg)
        tip.current.style.transform="translateX(0%)"
        tip.current.style.borderLeft =`7px solid ${state?"#249729":"red"}`
        tip.current.style.color =`${state?"#249729":"red"}`
        setTimeout(() => tip.current.style.transform="translateX(100%)", 2000);
    }

    async function unsell(tokenId) {

      setLoading(true)
      try {
           const provider = new ethers.providers.Web3Provider(window.ethereum);
           const signer = provider.getSigner();

           const contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer);
           const transaction=await contract.unSellToken(tokenId)

           await transaction.wait();
          
          toolTip("You successfully updated the NFT!", true)
          setLoading(false)
      } catch (error) {
          toolTip("Error to listed the NFT!")
          setLoading(false)
      }
  
  }

  async function sellNFT(tokenId,price) {
    
      if (priceSell<0.00000001) return setPrice(0)
      setLoading(true)
      try {
           const provider = new ethers.providers.Web3Provider(window.ethereum);
           const signer = provider.getSigner();

           const contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer);

           const listPrice=await contract.getListPrice()

           const salePrice = ethers.utils.parseUnits(price.toString(), 'ether')

           const transaction = await contract.resellToken(tokenId,salePrice,{value:listPrice});

           await transaction.wait();
          
          toolTip("You successfully Listed the NFT!", true)
          setLoading(false)
      } catch (error) {
          toolTip("Error to listed the NFT!")
          setLoading(false)
      }
  
  }

  async function checkTransactionStatus(transactionHash) {
    const success = {};
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const txReceipt = await provider.waitForTransaction(transactionHash);
    if (txReceipt && txReceipt.confirmations) {
      success.ok = true;
      return success;
    } else {
      success.ok = false;
      return success;
    }
  }

    const transferNftWallet = async () => {
        const tokenId = data.tokenId;
        if (!wallet) return;
        setLoading(true);
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            MarketplaceJSON.address,
            MarketplaceJSON.abi,
            signer
          );
          const transaction = await contract.transferToken(wallet, tokenId);
          const success = await checkTransactionStatus(transaction.hash);
          if (!success.ok) throw new Error("Algo no fue bien");
          toolTip("Succesfully Nft has been transfered", true);
          setLoading(false);
          setTimeout(() => window.location.reload(), 2200);
        } catch {
          toolTip("Error Nft hasnt been transfered");
          setLoading(false);
        }
      };
    

    return(
        <main className="mt-40 mx-auto max-w-screen-xl text-3xl min-h-[900px] grid md:grid-cols-2 grid-cols-1 items-center gap-x-14 px-20">
                {loading && <div className="fixed bg-white/50 backdrop-blur-xl top-0 bottom-0 left-0 w-full z-50 text-[#249729] flex  items-center justify-center text-7xl">Loading....</div>}
                {<div className="fixed top-0 right-0 z-50 text-3xl translate-x-[100%] h-40 bg-white rounded-xl px-4 py-2 flex flex-wrap w-[300px] shadow-2xl justify-center items-center" ref={tip}>{message}</div>}
                <h1 className="md:col-span-2 col-span-1 text-center text-6xl">Transferir Nft</h1>
                <div className="h-full flex items-center justify-center">
                  <img src={data.image} alt="image nft" className="rounded-xl aspect-square object-cover shadow-xl min-h-[80%]" />
                </div>
                <div className="actions h-[600px] flex flex-col gap-7 ">
                  <div className="flex items-center justify-between">
                    <h2 className="py-4">{data.name}</h2>
                    <h2>Id del Token : #{data.tokenId}</h2>
                  </div>
                <div className="flex flex-wrap">
                  <h2 className="break-all">Contract : {data.contract}</h2>
                </div>
                {!data.listed?
                <section className="">

                <div className="flex flex-col gap-7">
                    <label htmlFor="price" className="text-gray-400">
                    Precio:
                    </label>
                    <input
                    type="number"
                    id="price"
                    placeholder="0.0001 MATIC"
                    className="bg-gray-700 text-white rounded-lg p-2"
                    min={0.0001}
                    onChange={(e) => setPrice(e.target.value)}
                    />
                    {priceSell === 0 && (
                    <span className="text-red-500">
                        Debes poner un precio primero
                    </span>
                    )}
                    {priceSell > 0 && (
                    <div>
                        <span className="text-gray-400">
                        La comisión de venta para Promolider será de:
                        </span>
                        <br />
                        <span className="text-gray-400">
                        {priceSell * (0.05)} MATIC
                        </span>
                    </div>
                    )}
                </div>
                <button
                    className="border-b-2 px-4 py-2 rounded-xl my-3 w-full"
                    onClick={() => sellNFT(data.tokenId, priceSell)}
                >
                    Listar en el Marketplace
                </button>
                </section>:
                <button onClick={()=>unsell(data.tokenId)}>Quitar del Marketplace</button>
                }
                <div className="my-20 relative flex items-center justify-center">
                  <div className="w-full border "></div>
                  <h2 className="text-center absolute p-4 bg-[#131b1e] rounded-3xl">O</h2>
                </div>

                <div className="flex flex-col gap-7">
                    <label htmlFor="wallet" className="text-gray-400">
                    Ingrese la wallet a transferir:
                    </label>
                    <input
                    type="text"
                    id="walletAdress"
                    placeholder="Escribe la dirección de la wallet..."
                    className="bg-gray-700 text-white rounded-lg p-2"
                    value={wallet}
                    onChange={(e) => setWallet(e.target.value)}
                    />
                    <button
                    className="border-b-2 px-4 py-2 rounded-xl"
                    onClick={transferNftWallet}
                    >
                    Transferir a otra Wallet
                    </button>
                </div>
              </div>
        </main>
    )
}