import { Link } from "react-router-dom";

export function LoadingCollect() {
    return(
        <>
                    <div className="row box-card jc-evenly-md h-[610px]">
                    <div className="col4 card collect bg-white10 col5-md col6-s" data-item="pattern">
                        <div className="loading-card"></div>
                        <div className=" row jc-between spacetop2">
                            <div>
                                <p className="size2 halfwhite">
                                    @Promolider
                                </p>
                                <h5 className="size2 bold"></h5>
                            </div>
                            <div>
                                <p className="current halfwhite">Matic</p>
                                <h5 className="size2 bold">MATIC</h5>
                            </div>
                        </div>
                            <Link to={`/nftPage/`} className="bid size2 ">
                            Visualizar NFT
                        </Link>
                    </div>
                    <div className="col4 card collect bg-white10 col5-md col6-s" data-item="pattern">
                        <div className="loading-card"></div>
                        <div className=" row jc-between spacetop2">
                            <div>
                                <p className="size2 halfwhite">
                                    @Promolider
                                </p>
                                <h5 className="size2 bold"></h5>
                            </div>
                            <div>
                                <p className="current halfwhite">Matic</p>
                                <h5 className="size2 bold">MATIC</h5>
                            </div>
                        </div>
                            <Link to={`/nftPage/`} className="bid size2 ">
                            Visualizar NFT
                        </Link>
                    </div>
                    <div className="col4 card collect bg-white10 col5-md col6-s" data-item="pattern">
                        <div className="loading-card"></div>
                        <div className=" row jc-between spacetop2">
                            <div>
                                <p className="size2 halfwhite">
                                    @Promolider
                                </p>
                                <h5 className="size2 bold"></h5>
                            </div>
                            <div>
                                <p className="current halfwhite">Matic</p>
                                <h5 className="size2 bold">MATIC</h5>
                            </div>
                        </div>
                            <Link to={`/nftPage/`} className="bid size2 ">
                            Visualizar NFT
                        </Link>
                    </div>
                    </div>

        </>
    )
}