import { LoadingCollect } from "../Loading/Loading";
import ProfileCard from "./ProfileCard";

export function ProfileCOmponent({data,loading,setTransferLoading,toolTip}) {

    if (loading) return <LoadingCollect/>

    return(
        <div className="flex justify-center flex-wrap max-w-screen-xl gap-7">
                        {data.length>0?data.map(({value}, index) => {
                            return <ProfileCard data={value} key={index} toolTip={toolTip} setLoading={setTransferLoading}></ProfileCard>;
                        }):<div className="mt-10 text-4xl h-[610px] flex items-center col-span-2">
                        "Oops, No NFT data to display or your wallet isn't connected?" 
                    </div>}
        </div>
    )
}