import { Link } from "react-router-dom";
import "../../NFTTile.css";

function NFTTile({ data }) {

  const route = data?.tokenId;



  return (
    <div className="card">
      <img src={data.image} className="card-img" alt="" />
      <div className="info">
        <div className="flex justify-between items-center mb-2">
          <p className="text-gray-400">@Promolider</p>
          <p className="text-gray-400">Matic</p>
        </div>
        <div className="flex justify-between items-center mb-4">
          <h5 className="font-bold">{data.name}</h5>
          <h5 className="font-bold">{data.price} MATIC</h5>
        </div>
      </div>
      <Link
        to={`${route ? `/nftPage/${data.contract}/${route}` : "/marketplace"}`}
        className="bid size2"
      >
        {route ? "Visualizar NFT" : "Explora Mas"}
      </Link>
    </div>
  );
}

export default NFTTile;

