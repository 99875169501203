import NFTTile from "./NFTTile";
import { LoadingCollect } from "../Loading/Loading";

export function CollectCard({data,loading}) {

    if (loading) return <LoadingCollect/>

    return(
        <div className="row box-card jc-evenly-md min-h-[600px] justify-center gap-10">
                        {data.length>0?data.map(({value}, index) => {
                            return <NFTTile data={value} key={index}></NFTTile>;
                        }):<div className="text-7xl mx-auto flex items-center">Sold Out </div>}
        </div>
    )
}