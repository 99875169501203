import { Outlet } from "react-router";
import {Navbar} from "../components/Navbar/Navbar";
import { Footer } from "../components/footer/footer";

export function Layaout({children}) {
    return(
        <>
        <Navbar/>
            <Outlet/>
        <Footer/>
        </>
    )
}