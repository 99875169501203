import fullLogo from "../../logo-promolider.png";

export function Footer() {
    return(
        <footer className="spacer5">
                <div className="container row jc-between flexcol-s ta-center-s">
                    <div className="row flexcol spacebottom3-s">
                        <a href="" className="logo bold white"><img className="logo_principal" src={fullLogo} alt="logo promolider" /></a>
                        <p className="size2 halfwhite spacetop2">El mejor sitio web del mercado NFT en
                            <br />el mundo y siente tu experiencia en
                            <br />vender o comprar nuestro trabajo</p>
                    </div>
                    <div className="row flexcol spacebottom3-s">
                        <h5 className="bold size2 titlegreen">Sobre nosotros</h5>
                        <a href="/" className="size2 halfwhite spacetop2">Productos</a>
                        <a href="/" className="size2 halfwhite spacetop2">Terminos y condiciones</a>
                        <a href="/" className="size2 halfwhite spacetop2">Preguntas frecuentes</a>
                    </div>
                    <div className="row flexcol spacebottom3-s">
                        <h5 className="bold size2 titlegreen">Compañía</h5>
                        <a href="/" className="size2 halfwhite spacetop2">Nuestro equipo</a>
                        <a href="/" className="size2 halfwhite spacetop2">Asociate con nosotros</a>
                        <a href="/" className="size2 halfwhite spacetop2">Política y privacidad</a>
                    </div>
                    <div className="row flexcol spacebottom3-s">
                        <h5 className="bold size2 titlegreen">Contacto</h5>
                        <a href="https://wa.me/917067350" className="size2 halfwhite spacetop2" target="_blank" rel="noreferrer">+51 917067350</a>
                        <a href="mailto:promoliderorg@gmail.com" target="_blank" rel="noreferrer" className="size2 halfwhite spacetop2">promoliderorg@gmail.com</a>
                        <div className="row jc-between spacetop1 jc-evenly-s">
                            <a href="https://www.facebook.com/profile.php?id=100063926738412" className="fab fa-facebook size2 halfwhite spacetop2" target="_blank" rel="noreferrer"></a>
                            <a href="https://www.linkedin.com/company/promol%C3%ADder/mycompany/" className="fab fa-linkedin size2 halfwhite spacetop2" target="_blank" rel="noreferrer"></a>
                            <a href="https://www.instagram.com/promoliderorg/?igshid=YmMyMTA2M2Y%3D" className="fab fa-instagram size2 halfwhite spacetop2" target="_blank" rel="noreferrer"></a>
                        </div>
                    </div>
                </div>
                <p className="size2 halfwhite spacetop10 ta-center">©COPYRIGHT 2022. PROMOLÍDER NFT. TODOS LOS DERECHOS RESERVADOS.</p>
            </footer>
    )
}