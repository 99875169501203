export function Questions() {
    return(
        <section className="spacer10" id="faq">
                <div className="container">
                    <h1 className="bold size6 ta-center titlegreen">Preguntas frecuentes</h1>
                    <p className="spacebottom5 halfwhite size2 ta-center">
                        ¿Quieres preguntar algo?
                    </p>
                    <div className="row jc-between">
                        <div className="box-faq col6 col12-s">
                            <div className="box spacebottom5">
                                <div className="title row jc-between">
                                    <h3 className="size2 halfwhite">¿Qué gano como socio fundador de Promolider?</h3>
                                    <i className="fas fa-angle-down size2 halfwhite"></i>
                                </div>
                                <p className="answer size2 spacetop1 ">Todo socio fundador de Promolider estarás participando en la distribución del 10% de
                                    la utilidad anual de la empresa entre los 100 poseedores de la edición limitada de los NFTS. </p>
                            </div>
                            <div className="box spacebottom5">
                                <div className="title row jc-between">
                                    <h3 className="size2 halfwhite">¿Porque poseer un NTF de Promolider?</h3>
                                    <i className="fas fa-angle-down size2 halfwhite"></i>
                                </div>
                                <p className="answer size2 spacetop1 ">El NFT es un contrato inteligente único, con garantía y respaldo financiero de Promolider.org</p>
                            </div>
                            <div className="box spacebottom5">
                                <div className="title row jc-between">
                                    <h3 className="size2 halfwhite">¿Qué tengo que hacer para ser socio de Promolider?</h3>
                                    <i className="fas fa-angle-down size2 halfwhite"></i>
                                </div>
                                <p className="answer size2 spacetop1 ">Debes adquirir una membresía de Promolíder y comprar un NFT</p>
                            </div>
                        </div>
                        <div className="box-faq col6 col12-s">
                            <div className="box spacebottom5">
                                <div className="title row jc-between">
                                    <h3 className="size2 halfwhite">¿Puedo vender o transferir mi NFT?</h3>
                                    <i className="fas fa-angle-down size2 halfwhite"></i>
                                </div>
                                <p className="answer size2 spacetop1 ">Sí, puedes venderlo en el momento que tu desees</p>
                            </div>
                            <div className="box spacebottom5">
                                <div className="title row jc-between">
                                    <h3 className="size2 halfwhite">¿Por qué en el mercado se han vendido NFTS tan caros?</h3>
                                    <i className="fas fa-angle-down size2 halfwhite"></i>
                                </div>
                                <p className="answer size2 spacetop1 ">En efecto hay NFT que se han vendido en millones de Dólares,
                                    y esto se debe a que son piezas únicas que adquieren valor en el tiempo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}