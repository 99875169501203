import './App.css';
import Marketplace from './components/Home/Marketplace';
import SellNFT from './components/SellNft/SellNFT';
import NFTPage from './components/Nft/NFTpage';
import {Routes,Route} from "react-router-dom";
import { ProtectedRoute } from './components/SellNft/ProtecRoute';
import ProfilePage from "./components/Profile/ProfilePage";
import {Login} from "./components/Login/Login";
import {CollectionPage} from "./components/Collectionspage/CollectionsPage";
import { Layaout } from './Layaout/Layaout';
import { ProtecPage } from './components/ProfileSell/ProtectPage';

function App() {
  return (
    <Routes>
          <Route path="/" element={<Layaout/>}>
            <Route path="/" element={<Marketplace/>}/>
            <Route path="/sellNFT" element={<ProtectedRoute><SellNFT /></ProtectedRoute>}/> 
            <Route path="/nftPage/:collection/:tokenId" element={<NFTPage />}/>        
            <Route path="/profile" element={<ProfilePage />}/> 
            <Route path="/profile/nft/:collection/:tokenId" element={<ProtecPage/>}/> 
            <Route path="/marketplace" element={<CollectionPage/>}/> 
            <Route path="/signin" element={<Login/>}/> 
            <Route path="*" element={<div className='text-5xl flex items-center h-[60vh] justify-center mt-40 '>Not found</div>}/> 
          </Route>
      </Routes>
  );
}

export default App;
