import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MarketplaceJSON from '../../Promolider.json';
import MarketplaceJSON2 from '../../Promolider2.json';
import MarketplaceJSON3 from '../../Promolider3.json';
import { ethers } from "ethers";
import { useAddress } from '../../Context/Account';

export default function NFTpage() {

    const [data, updateData] = useState({});
    const {connectWebsite}=useAddress()
    const [currAddress, updateCurrAddress] = useState("");
    const [addtionalInfo,setInfo]=useState([])
    const [loading,setLoading]=useState(true)
    const [loadingTransaction,setTransaction]=useState(false)
    const [message,setMessage]=useState("")
    const tip=useRef(null)
    const params = useParams();
    const tokenId = params.tokenId;
    const collection = params.collection;
    
    const toolTip=(msg,state)=>{
        setMessage(msg)
        tip.current.style.transform="translateX(0%)"
        tip.current.style.borderLeft =`7px solid ${state?"#249729":"red"}`
        tip.current.style.color =`${state?"#249729":"red"}`
        setTimeout(() => tip.current.style.transform="translateX(100%)", 2000);
    }

    async function buyNFT(tokenId) {
        if (!data.listed) return
        await connectWebsite()
        setTransaction(true)
        try {
             const provider = new ethers.providers.Web3Provider(window.ethereum);
             const signer = provider.getSigner();

             let contract
             if (collection=="Promolider1°") {
                 contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer);
             }else if (collection=="Promolider2°") {
                 contract = new ethers.Contract(MarketplaceJSON2.address, MarketplaceJSON2.abi, signer);
             }else if (collection=="Promolider3°") {
                 contract = new ethers.Contract(MarketplaceJSON3.address, MarketplaceJSON3.abi, signer);
             }
             const salePrice = ethers.utils.parseUnits(data.price.toString(), 'ether')
             const transaction = await contract.executeSale(tokenId, { value: salePrice });
 
             await transaction.wait();
            
             toolTip("Succesfully buying Nft",true)
             setTransaction(false)
             setTimeout(() => window.location.reload(), 1000);
            } catch (error) {
            toolTip("Error to buy Nft")
            setTransaction(false)

        }
    }


    useEffect(() => {
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_PROVIDER_URL);

        let contract
        if (collection=="Alfa") {
            contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, provider);
        }else if (collection=="Beta") {
            contract = new ethers.Contract(MarketplaceJSON2.address, MarketplaceJSON2.abi, provider);
        }else if (collection=="Omega") {
            contract = new ethers.Contract(MarketplaceJSON3.address, MarketplaceJSON3.abi, provider);
        }

        
        async function getNFTData(tokenId) {
            try {
                const tokenURI = await contract.tokenURI(tokenId);
    
                const listedToken = await contract.getListedTokenForId(tokenId);
    
                const listPrice=await contract.getListPrice()
                const comision = ethers.utils.formatUnits(listPrice.toString(), 'ether');
    
                let meta = await axios.get(tokenURI);
                meta = meta.data;
                const price=ethers.utils.formatUnits(listedToken.price.toString(),'ether')
                
                const item = {
                    price,
                    tokenId: listedToken.tokenId.toString().padStart(5,0),
                    seller: listedToken.seller,
                    owner: listedToken.owner,
                    listed:listedToken.currentlyListed,
                    image: meta.image,
                    name: meta.name,
                    description: meta.description,
                    comision,
                    collection,
                    terminos:meta.terminos
                };
    
                updateData(item);

                setLoading(false)
            } catch (error) {
                console.error("Error fetching NFT data:", error);
            }
        }

        async function getHisotry(tokenId) {
            
            const histories=await contract.getTokenHistory(tokenId)
            
            const historiesMaped=histories.map(history=>{
                
                const [owner,price,timestamp]=history
                
                const priceParsed=ethers.utils.formatUnits(price.toString(),"ether")
                
                const date=new Date(timestamp*1000)
                
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                
                
                const historyDate=`${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
                
                return {owner,priceParsed,date:historyDate}
            })
            setInfo(historiesMaped)
        }
        
        async function getAddress() {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer=provider.getSigner()
            const addr=await signer.getAddress()

            updateCurrAddress(addr)
        }
        
        getAddress()
        getHisotry(tokenId)
        getNFTData(tokenId);
    }, []);

    return (
    
            
        <div id="home">
        {loadingTransaction && <div className="fixed bg-white/50 backdrop-blur-xl top-0 bottom-0 left-0 w-full z-50 text-[#249729] flex items-center justify-center text-7xl">Loading....</div>}
        {<div className="fixed top-0 right-0 z-50 text-3xl translate-x-[100%] h-40 bg-white rounded-xl px-4 py-2 flex flex-wrap w-[350px] shadow-2xl justify-center items-center" ref={tip}>{message}</div>}
            <section id="about" className="h-auto mt-40">
                <div className="container ">
                    <h1 className="bold size6 ta-center titlegreen spacer5">Producto</h1>
                    <p className="spacebottom3 halfwhite size2 ta-center">
                        Adquiere tu NFT con<br />nosotros
                    </p>
                    <div className="row ai-center jc-between flexcol-s ">
                        <div className="col6 col12-s ta-center-s order-2 md:order-1">
                            <div className='flex gap-x-10 mb-6 justify-center md:justify-start'>
                                <h3 className="size3 bold">{data.name}</h3>
                                <h3 className='size3 bold'>#{data.tokenId}</h3>
                            </div>
                            <h2 className='size3 bold'>{data.collection} Edicion</h2>

                            <p className="size2 spacetop1 spacebottom3 halfwhite">{data.description}
                            </p>
                            
                            <h3 className="size3 bold">PRECIO</h3>
                            <p className="size2 spacetop1 spacebottom3 halfwhite">{data.price} MATIC</p>
                            <div className='flex flex-col gap-y-4'>
                                <h3 className="size2 bold">Terminos</h3>
                                <p className='size2'>{data.terminos}</p>
                            </div>
                            <div className="col12 col5-md col7-s swiper-slide">
                                <div className="card">
                                    <div className='flex md:justify-start justify-center'>
                                        {currAddress !== data.owner ?
                                            <button className="enableEthereumButton bid size2 ta-center" disabled={!data.listed} onClick={() => buyNFT(tokenId)}>{data.listed?"Comprar este NFT":"No disponible"}</button>
                                            : <div className="size2 titlegreen">Este NFT es de tu propiedad</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col6 col10-s spacebottom2-s order-1 card bg-white10 max-w-[400px] object-cover ">
                            <div>
                                {loading?<div className="loading-card"></div>:<img src={data.image} className="img-responsive" />}
                            </div>
                        </div>
                    </div>
                </div>
                <footer className='container overflow-x-auto'>
                    <div className='mt-40'>
                    <h1 className='text-4xl mb-20'>Latest Transactions</h1>
                    <div className='flex flex-col gap-y-5'>

                    {addtionalInfo.map(info=>{
                        return(
                            <div className='grid grid-cols-4 text-2xl text-gray-400 w-full bor-bottom py-4  min-w-[900px]' key={info.date}>
                                <div className='col-span-2'>
                                    <h1>Direccion del owner:</h1>
                                    <h2>{info.owner}</h2>
                                </div>
                                <div className='col-span-1'>
                                    <h1>Transaccion realizada el:</h1>
                                    <h2>{info.date}</h2>
                                </div>
                                <div className='col-span-1'>
                                    <h1>Precio de Compra:</h1>
                                    <h2>{info.priceParsed} MATIC</h2>
                                </div>
                            </div>
                        )
                    })}
                    </div>
                    </div>
                </footer>
            </section>

        </div >
        
    );
}