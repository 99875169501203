import { signInWithEmailAndPassword,signOut } from "firebase/auth";
import {  auth } from "../../firebase/firebaseCredentials";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const Email=()=><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-mail"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>
const Password=()=><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-key"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z" /><path d="M15 9h.01" /></svg>

export function Login() {

    const [error,setError]=useState("")
    const [validate,setValidate]=useState({email:"",password:""})
    const navigate=useNavigate()

    const handleSubmit=async (e)=>{
        e.preventDefault()
        const email=e.target?.email.value
        const password=e.target?.password.value
        
        if (!checkCredentials(email,password)) {
            return
        }

        try {
            await signInWithEmailAndPassword(auth,email,password)
            navigate("/sellNft")
        } catch (error) {
            setError("Invalid email or password")
            setValidate({email:"",password:""})
            setTimeout(() =>setError(""), 2000);
        }
    }

    const checkCredentials=(email,password)=>{
;
        if (!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) || !password) {
            const validateEmail=!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))?"Email is incorrect":""
            const validatePassword=!password?"Password is required":""

            setValidate({email:validateEmail,password:validatePassword})
            return false
        }

        return true
    }

    useEffect(()=>{
        signOut(auth)
    },[])

    return(
            <main className="h-auto relative flex items-center justify-center mt-40 min-h-[800px]">
                <div className="absolute blur-md inset-0 -z-10 m-auto flex justify-center items-center">
                    <img src="/img/logo-promolider.png" alt="logo-promolider" className=""/>
                </div>
                <form onSubmit={handleSubmit} className="max-w-2xl min-w-[350px] md:w-[400px] flex h-[470px] flex-col items-center gap-7 text-4xl backdrop-blur-lg bg-white/10 px-10 py-10 rounded-3xl " >
                        <h1 className="text-[#36ca3d]" >SIGN IN</h1>
                        <h3 >!Welcome</h3>
                        <div className="text-center mb-6 h-2 text-[#c94444] relative">{error}</div>
                    <div className="flex flex-col w-full gap-8">
                        <div className="flex flex-col gap-4 w-full">
                            <label htmlFor="email" className="flex gap-x-4 items-center"><Email/>Email</label>
                            <input type="text"  name="email" id="email" className="px-4 pl-8 py-4 text-2xl rounded-full bg-transparent border-white border" 
                            placeholder="email@example.com"/>
                            <span className="text-2xl  h-8 text-[#45ff45]">{validate.email}</span>
                        </div>
                        <div className="flex flex-col gap-4 w-full">
                            <label htmlFor="password" className="flex gap-x-4 items-center"><Password/>Password</label>
                            <input type="password"  name="password" id="password" className="px-4 pl-8 py-4 text-2xl rounded-full bg-transparent border-white border" placeholder="your password......"/>
                            <span className="text-2xl h-8 text-[#45ff45]">{validate.password}</span>
                        </div>
                    </div>
                    <button className="text-white bg-[#36ca3d] px-4 py-2 w-full hover:bg-opacity-50 rounded-2xl">Iniciar Sesion</button>
                </form>
            </main>
    )
}