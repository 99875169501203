
const sampleData = [
    {
        "name": "Buho Graduado",
        "description": "Alchemy's First NFT",
        "website": "http://axieinfinity.io",
        "image": "img/NFT002.png",
        "price": "0.03",
        "currentlySelling": "True",
        "partnerImage":"img/photo1.png",
        "address": "0xe81Bf5A757CB4f7F82a2F23b1e59bE45c33c5b13",
    },
    {
        "name": "Buho Nadador",
        "description": "Alchemy's Second NFT",
        "website": "img/NFT003.png",
        "image": "img/NFT003.png",
        "price": "0.03",
        "currentlySelling": "True",
        "partnerImage":"img/photo2.png",
        "address": "0xe81Bf5A757C4f7F82a2F23b1e59bE45c33c5b13",
    },
    {
        "name": "Buho Cantante",
        "description": "Alchemy's Third NFT",
        "website": "http://axieinfinity.io",
        "image": "img/NFT004.png",
        "price": "0.03",
        "currentlySelling": "True",
        "partnerImage":"img/photo3.png",
        "address": "0xe81Bf5A757C4f7F82a2F23b1e59bE45c33c5b13",
    }
];



export function Partnerts() {
    return(
        <section className="creator spacer10">
                <div className="container">
                    <h1 className="bold size6 ta-center titlegreen">Nuestros socios</h1>
                    <p className="spacebottom3 halfwhite size2 ta-center">
                        Personas que se convirtieron en nuestros socios<br />al adquirir su NFT
                    </p>
                    <div className="swiper row creator-slider">
                        <div className="swiper-wrapper">
                            {sampleData.map(sample=>{
                                return(
                                    <div className="col4 col5-md col7-s swiper-slide" key={sample.description}>
                                        <div className="card-creator bg-white10 ta-center">
                                            <img src={sample.image} className="img-responsive" alt="" />
                                            <img src={sample.partnerImage} className="photo nft-logo" alt="" />
                                            <h5 className="spacer1 size2 bold titlegreen">Maria Perez</h5>
                                            <p className="spacebottom2 halfwhite desc">Descripción de la ocupación
                                                <br />de la persona</p>
                                            <button className="follow ta-center bg-green white">+ Seguir</button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section >
    )
}