import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ethers } from "ethers";
import MarketplaceJSON from "../../Promolider.json";
import MarketplaceJSON2 from "../../Promolider2.json";
import MarketplaceJSON3 from "../../Promolider3.json";
import { CollectCard } from "./Cards";

export function Collections() {
    const [data, updateData] = useState([]);
    const [filter,setFilter]=useState("0")
    const [loading,setLoading]=useState(true)
    const insta=useRef(null)
    
    const getAllNFTsbyCollection=async(e)=>{
        setLoading(true)
        setFilter(e.target.value)
            const nameCollection=e.target.value    

            const contractDecided={}

            if (nameCollection==0) {
                contractDecided.address=MarketplaceJSON.address
                contractDecided.abi=MarketplaceJSON.abi
                contractDecided.collection="Alfa"
            }else if(nameCollection==1){
                contractDecided.address=MarketplaceJSON2.address
                contractDecided.abi=MarketplaceJSON2.abi
                contractDecided.collection="Beta"
            }else if(nameCollection==2){
                contractDecided.address=MarketplaceJSON3.address
                contractDecided.abi=MarketplaceJSON3.abi
                contractDecided.collection="Omega"
            }

            const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_PROVIDER_URL);
            const contract = new ethers.Contract(contractDecided.address, contractDecided.abi, provider)
            const nfts = await contract.getAllNFTs()

            const items = await Promise.allSettled(nfts.map(async nft => {
                const tokenURI = await contract.tokenURI(nft.tokenId);
                let meta = await axios.get(tokenURI);
                meta = meta.data;
                
                const price = ethers.utils.formatUnits(nft.price.toString(), 'ether');
                
                const item = {
                    price,
                    tokenId: nft.tokenId.toNumber(),
                    seller: nft.seller,
                    owner: nft.owner,
                    image: meta.image,
                    name: meta.name,
                    description: meta.description,
                    contract:contractDecided.collection
                }
                return item;
            }))
            setLoading(false)
            updateData(items)
    }

    useEffect(()=>{
            insta.current?.click()
    },[])
    

    return (
        <section className="collections pt-40" id="collections">
                <div className="container">
                    <h1 className="bold md:size6 size5 ta-center titlegreen">Colecciones</h1>
                    <p className="spacebottom3 halfwhite size2 ta-center">
                        Visualiza nuestra gran colección de NFT<br />que tenemos para ti
                    </p>
                    <div className="row spacebottom3 filter-buttons justify-between px-32">
                        <button onClick={getAllNFTsbyCollection} ref={insta} className={`${filter==="0"?"text-green-600":""} col3 filter col6-xs spacebottom1-xs`} data-filter="art" value={"0"}>Alfa Edicion</button>
                        <button onClick={getAllNFTsbyCollection} className={`${filter==="1"?"text-green-600":""} col3 filter col6-xs`} data-filter="photograpy" value={"1"}>Beta Edicion</button>
                        <button onClick={getAllNFTsbyCollection} className={`${filter==="2"?"text-green-600":""} col3 filter col6-xs`} data-filter="pattern" value={"2"}>Omega Edicion</button>
                    </div>
                    <CollectCard data={data} loading={loading}/>
                </div>
            </section>
    )
}