import { useEffect, useState } from "react";
import { ProfilePageSell } from "./ProfilePageSell";
import axios from "axios";
import { Navigate, useParams } from "react-router-dom";
import MarketplaceJSON from "../../Promolider.json";
import MarketplaceJSON2 from "../../Promolider2.json";
import MarketplaceJSON3 from "../../Promolider3.json";
import { ethers } from "ethers";

export function ProtecPage() {
    
    const [data,updateData]=useState([])
    const [loading,setLoading]=useState(true)
    const params = useParams();
    const tokenId=params?.tokenId
    const collection=params?.collection

    useEffect(()=>{
        const provider= new ethers.providers.Web3Provider(window.ethereum)
        const signer=provider.getSigner()
        let contract
        if (collection=="Alfa") {
            contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer);
        }else if (collection=="Beta") {
            contract = new ethers.Contract(MarketplaceJSON2.address, MarketplaceJSON2.abi, signer);
        }else if (collection=="Omega") {
            contract = new ethers.Contract(MarketplaceJSON3.address, MarketplaceJSON3.abi, signer);
        }
        
        async function nft() {
            try {
                const addr=await signer.getAddress()
                
                const tokenURI = await contract.tokenURI(tokenId);
                
                const listedToken = await contract.getListedTokenForId(tokenId);
                
                const listPrice=await contract.getListPrice()
                const comision = ethers.utils.formatUnits(listPrice.toString(), 'ether');
                
                let meta = await axios.get(tokenURI);
                meta = meta.data;
                const price=ethers.utils.formatUnits(listedToken.price.toString(),'ether')

                const item = {
                    price,
                    tokenId: listedToken.tokenId.toString().padStart(5,0),
                    seller: listedToken.seller,
                    owner: listedToken.owner,
                    listed:listedToken.currentlyListed,
                    image: meta.image,
                    name: meta.name,
                    description: meta.description,
                    comision,
                    collection,
                    addr,
                    contract:contract.address,
                    terminos:meta.terminos
                };
                updateData(item);
            } catch (error) {
                updateData({owner:undefined})
            }finally{
                setLoading(false)
            }
        }
        nft()

      },[])

      if (loading) return <div className="min-h-[900px] mt-40 grid place-content-center text-3xl">Loading...</div>

    return data?.owner==data.addr?<ProfilePageSell data={data}/>:<Navigate to={`/nftPage/${collection}/${tokenId}`} />
          
}