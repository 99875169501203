import fullLogo from '../../logo-promolider.png';
import {Link, useLocation} from "react-router-dom";
import { useEffect, useState } from 'react';
import { useAddress } from '../../Context/Account';

export function Navbar() {

  const {address,setAddress,connected,toggleConnect,connectWebsite}=useAddress()
  const [currentPath,setPath]=useState("")
  const [toggle,setToggle]=useState(true)

  const location=useLocation()
  const adrInit=address.substring(0,4).toUpperCase()
  const adrLast=address.substring(address.length-3).toUpperCase()
  

  useEffect(() => {

    const handleAccountsChanged=async (accounts)=> {
      if (accounts==0) {
        setAddress("")
        toggleConnect(false)
        sessionStorage.clear()
        window.location.reload()
        return 
      }
      await connectWebsite()
      window.location.reload()
    }

    if (!connected) {
      const conexion=sessionStorage.getItem("connect")
      const address=sessionStorage.getItem("address")
      
      if (conexion==="true") {
        toggleConnect(true);
        setAddress(address)
      }
    }
    
    if (window.ethereum) {
      window.ethereum.on('accountsChanged',handleAccountsChanged )
    }
    
    return ()=>window.ethereum.removeListener('accountsChanged', handleAccountsChanged);


  },[]);

  useEffect(()=>{
    const url=window.location.pathname
    setPath(url)
  },[location.pathname])


  return (
    <header className="header lg:bg-white/10 backdrop-blur-md z-50" id="navbar">
      <div className="container">
        <div className="logo spacetop1 bold white">
          <Link to="/">
            <img src={fullLogo} alt="logo promolider" width={200} height={120} className="inline-block -mt-2 object-cover" />
          </Link>
        </div>
        <div className={`menu ${toggle && "active"}` }>
          <Link to="/marketplace"><span className={`${currentPath=="/marketplace"?"text-[#36ca3d]":""}`}>Marketplace</span></Link>
          <Link to="/signin" ><span className={`${currentPath=="/signin"?"text-[#36ca3d]":""}`}>SignIn</span></Link>
          <Link to="/profile"><span className={`${currentPath=="/profile"?"text-[#36ca3d]":""}`} >Profile</span></Link>
          {!connected ?<button className="enableEthereumButton btn bg-green wallet" onClick={connectWebsite}>Connect Wallet</button>
            :<p className='overflow-hidden text-ellipsis w-40 text-3xl'>{adrInit}...{adrLast}</p>
          }
        </div>
        <div className="fas fa-bars" id="bar" onClick={()=>setToggle(!toggle)}></div>
        
      </div>
    </header>

  );
}